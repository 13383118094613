// @ts-nocheck
import axios from "axios";
import * as api from "./API";
import { Student } from "../tsmodels/Coach/student";
import { handleAxiosError } from "./Error";
import apiClient from "./Fetcher";

export const AddTrainee = async (studentName: string) => {
    try {
        const response = await apiClient.get(`/coach/add?studentLichessName=${studentName}`);
        return response;
    } catch (error: any) {
        handleAxiosError(error);
    }
};

export const AllTraineeWithStat = async (coachid: number): Promise<AxiosResponse<any>> => {
    try {
        const response = await apiClient.get(`/coach/AllTraineeInfo/${coachid}`);
        return response;
    } catch (error: any) {
        handleAxiosError(error);
    }
};
export const addCoachByToken = async (token: string): Promise<AxiosResponse<any>> => {
    try {
        const response = await apiClient.get(`/coachtoken/${token}`);
        return response;
    } catch (error: any) {
        handleAxiosError(error);
    }
};

export const CoachLink = async (): Promise<AxiosResponse<any>> => {
    try {
        const response = await apiClient.get(`/coach/coachlink`);
        return response;
    } catch (error: any) {
        handleAxiosError(error);
    }
};