const nb = {
    "buttons:flipper": "Vend sider av bordet",
    "buttons:first": "Gå til første trekket",
    "buttons:prev": "Gå til forrige trekk",
    "buttons:next": "Gå til neste trekk",
    "buttons:play": "Play / slutte å spille alle trekk",
    "buttons:last": "Gå til siste trekk",
    "buttons:deleteVar": "Slett variasjon",
    "buttons:promoteVar": "Fremme variasjon",
    "buttons:deleteMoves": "Slett resten beveger seg fra her",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Visning PGN av dagens spill",
    "buttons:hidePGN": "Skjul vises PGN",
    "buttons:getHint": "Gi et hint",
    "buttons:makeMove": "Vis neste trekk",
    "buttons:showSolution": "Vis hele løsningen",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "T",
    "chess:b": "L",
    "chess:n": "S",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "T",
    "chess:B": "L",
    "chess:N": "S"
};
export default nb;
