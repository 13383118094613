import axios from "axios";
import * as api from "./API";
import { IHomework } from "../Interfaces/Homework";

const CreateHomework = async (howework: any): Promise<any> => {
    try {
        var response = await axios.post(
            `${api.protocol}://${api.backendHost}/api/homework/add`,
            howework,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response;
    } catch (error: any) {
        // Логируем ошибку для отладки
        console.error("Ошибка при создании домашнего задания:", error);

        // Проверяем тип ошибки и создаем свой объект
        if (error.response) {
            throw new Error(
                `Ошибка ${error.response.status}: ${error.response.data.message || "Ошибка на сервере"
                }`
            );
        } else if (error.request) {
            throw new Error("Нет ответа от сервера. Проверьте подключение.");
        } else {
            throw new Error(`Ошибка: ${error.message}`);
        }
    }
};
export { CreateHomework };

export const AddPuzzleToHomework = async (
    howeworkId: number,
    puzzleId: number
): Promise<any> => {
    var response = await axios.post(
        `${api.protocol}://${api.backendHost}/api/homework/${howeworkId}/${puzzleId}`,
        { emptyBody: 5 },
        {
            withCredentials: true, // Разрешаем отправку куки с запросом
        }
    );
    console.log(response.data);
    return response.data;
};

let GetHomework = async (): Promise<any> => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homework/GetHomeworks`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response;
    } catch (error) {
        // Логируем ошибку для отладки
        // console.error("Ошибка при создании домашнего задания:", error);
        var responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке домашних заданий, попробуйте еще раз",
            },
        };
        // Возвращаем собственный объект с информацией об ошибке
        return responseError;
    }
};
export { GetHomework };

export const AssignHomework = async (
    studentName: string,
    homeworkTheme: string
): Promise<any> => {
    try {
        var response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/coach/assignhomework?studentname=${studentName}&homeworktheme=${homeworkTheme}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        console.log(response.data);
        return response;
    } catch {
        var responseError = {
            status: 500,
            data: {
                message: "Ошибка при добавлении домашних заданий",
            },
        };
        // Возвращаем собственный объект с информацией об ошибке
        return responseError;
    }
};


export let CopyHomework = async (owner: number, theme: string) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homework/CopyHomework/${owner}/${theme}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response;
    } catch (error) {
        // Логируем ошибку для отладки
        // console.error("Ошибка при создании домашнего задания:", error);
        // Возвращаем собственный объект с информацией об ошибке
        return error;
    }
};


export let GenerateCustomHomework = async (
    customname: string,
    theme: string,
    count: number,
    min: number,
    max: number
) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homework/Generatehomework/${customname}/${theme}/${count}/${min}/${max}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response;
    } catch (error) {
        return error;
    }
};

export let RemoveHomework = async (homeworkid : number) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homework/remove/${homeworkid}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response;
    } catch (error) {
        // Логируем ошибку для отладки
        // console.error("Ошибка при создании домашнего задания:", error);
        // Возвращаем собственный объект с информацией об ошибке
        return error;
    }
};