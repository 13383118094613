const is = {
    "buttons:flipper": "Flip hliðar borðsins",
    "buttons:first": "Fara á fyrsta ferðinni",
    "buttons:prev": "Fara í fyrri ferðinni",
    "buttons:next": "Fara á næsta hreyfa",
    "buttons:play": "Play / hætta að spila allt færist",
    "buttons:last": "Fara að síðasta færa",
    "buttons:deleteVar": "Eyða tilbrigði",
    "buttons:promoteVar": "Efla tilbrigði",
    "buttons:deleteMoves": "Eyða hvíld færist frá hér",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Sýna PGN núverandi leik",
    "buttons:hidePGN": "Fela birtist PGN",
    "buttons:getHint": "Gefðu vísbendingu",
    "buttons:makeMove": "Sýndu næstu hreyfingu",
    "buttons:showSolution": "Sýndu alla lausnina",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "H",
    "chess:b": "B",
    "chess:n": "R",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "H",
    "chess:B": "B",
    "chess:N": "R"
};
export default is;
