// @ts-nocheck
import React, { useState, useMemo, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Card,
    CardContent,
    Typography,
    CardActions,
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Snackbar,
    Alert,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { RemoveHomework } from "../../Utils/API/Homework";

interface Homework {
    id: number;
    theme: string;
    timeSpent: number;
    owner: number;
    studentId: number;
}

interface CoachModalProps {
    isOpen: boolean;
    closeModal: () => void;
    homeworks: Homework[];
}

const CoachModal: React.FC<CoachModalProps> = ({
    isOpen,
    closeModal,
    homeworks,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [localHomeworks, setLocalHomeworks] = useState<Homework[]>(homeworks);
    const [homeworkToDelete, setHomeworkToDelete] = useState<Homework | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

    // Обновление локального состояния при изменении пропсов
    useEffect(() => {
        setLocalHomeworks(homeworks);
    }, [homeworks]);

    // Функция для расчёта средней затраченной времени на задания
    const calculateAverageTime = useMemo(() => {
        const completedHomeworks = localHomeworks.filter((hw) => hw.timeSpent > 0);
        if (completedHomeworks.length === 0) return 0;
        const totalTime = completedHomeworks.reduce(
            (acc, hw) => acc + hw.timeSpent,
            0
        );
        return Math.round(totalTime / completedHomeworks.length);
    }, [localHomeworks]);

    // Обработка изменения строки поиска с debounce
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    // Фильтрация домашних заданий по теме
    const filteredHomeworks = useMemo(() => {
        return localHomeworks.filter((hw) =>
            hw.theme.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [localHomeworks, searchTerm]);

    // Очистка строки поиска
    const clearSearch = () => {
        setSearchTerm("");
    };

    // Обработка нажатия на кнопку удаления
    const handleDeleteClick = (hw: Homework) => {
        setHomeworkToDelete(hw);
    };

    // Подтверждение удаления
    const confirmDelete = async () => {
        if (homeworkToDelete) {
            try {
                await RemoveHomework(homeworkToDelete.id);
                setLocalHomeworks((prevHomeworks) =>
                    prevHomeworks.filter((hw) => hw.id !== homeworkToDelete.id)
                );
                setSnackbarMessage("Домашнее задание успешно удалено.");
                setSnackbarSeverity("success");
            } catch (error) {
                console.error("Ошибка при удалении домашнего задания:", error);
                setSnackbarMessage("Не удалось удалить домашнее задание.");
                setSnackbarSeverity("error");
            } finally {
                setHomeworkToDelete(null);
                setSnackbarOpen(true);
            }
        }
    };

    // Отмена удаления
    const cancelDelete = () => {
        setHomeworkToDelete(null);
    };

    // Закрытие Snackbar
    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={closeModal}
                fullWidth
                maxWidth="md"
                transitionDuration={{ enter: 500, exit: 300 }}
            >
                <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Мои Домашние Задания
                </DialogTitle>
                <DialogContent>
                    {/* Строка поиска */}
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Поиск по названию..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                                endAdornment: searchTerm && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearSearch} edge="end">
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    {/* Список домашних заданий */}
                    {filteredHomeworks.length === 0 ? (
                        <Typography variant="body1" align="center">
                            Нет доступных домашних заданий.
                        </Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {filteredHomeworks.map((hw) => (
                                <Grid item xs={12} sm={6} md={4} key={hw.id}>
                                    <Card
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            transition: "transform 0.2s, box-shadow 0.2s",
                                            "&:hover": {
                                                transform: "scale(1.02)",
                                                boxShadow: 6,
                                            },
                                        }}
                                    >
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography variant="h6" gutterBottom>
                                                {hw.theme}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Время выполнения:{" "}
                                                {hw.timeSpent > 0 ? `${hw.timeSpent} мин` : "—"}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                component={Link}
                                                to={`/homeworkgrid/${hw.theme}/${hw.id}`}
                                                startIcon={<VisibilityIcon />}
                                                fullWidth
                                            >
                                                Просмотр
                                            </Button>
                                            <IconButton
                                                aria-label="delete"
                                                color="error"
                                                onClick={() => handleDeleteClick(hw)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    {/* Среднее время выполнения */}
                    {localHomeworks.length > 0 && (
                        <Typography variant="subtitle1" sx={{ mt: 3, textAlign: "center" }}>
                            Среднее время выполнения: {calculateAverageTime} мин
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", p: 2 }}>
                    <Button onClick={closeModal} variant="outlined" color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог подтверждения удаления */}
            <Dialog
                open={Boolean(homeworkToDelete)}
                onClose={cancelDelete}
            >
                <DialogTitle>Подтверждение удаления</DialogTitle>
                <DialogContent>
                    <Typography>
                        Вы уверены, что хотите удалить домашнее задание "{homeworkToDelete?.theme}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={confirmDelete} color="error" variant="contained">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Всплывающее уведомление */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
};

export default CoachModal;
