import axios from "axios";
import * as api from "./API";
import Cookies from "js-cookie";

let GetPuzzleAnalys = async (puzzleid, studentid) => {
    var response = await axios.get(
        `${api.protocol}://${api.backendHost}/api/analysis/analysis/${puzzleid}/${studentid}`,
        {
            withCredentials: true,
        }
    );
    return response.data;
};

export {GetPuzzleAnalys}