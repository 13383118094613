// HomeworkGrid.jsx
//@ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import { Chessboard } from "react-chessboard";
import { useNavigate, useParams } from "react-router-dom";
import "../Styles/HomeworkGrid.css";
import { UserContext } from "./UserContext";
import Notification from "./Modals/Notification";
import AddHomeworkModal from "./Modals/AddHomeworkModal";
import { Grid, Card, CardContent, Typography, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
    GetHomeworkStat,
    GetStudentHomeworkGrid,
    GetHomeworkGrid,
} from "../Utils/API/HomeworkRepository";

const HomeworkGrid = () => {
    const { user } = useContext(UserContext); // Информация о пользователе
    const [puzzles, setPuzzles] = useState([]);
    const [aggregateStats, setAggregateStats] = useState(null); // Новое состояние
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState(null);
    const navigate = useNavigate();
    const [showAddModal, setShowAddModal] = useState(false); // Состояние модального окна
    const { owner, homeworktheme, action, studentid, homeworkid } = useParams();

    const showDetails = !!studentid;

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Параметры сетки:", studentid, owner, action, homeworkid);
                // Получение статистики домашнего задания
                if (studentid !== undefined) {
                    const homeworkStatRepo = await GetHomeworkStat(homeworkid, studentid);
                    setAggregateStats(homeworkStatRepo);
                    console.log("НОВАЯ статистика Домашки", homeworkStatRepo);
                    const GridStudentRepo = await GetStudentHomeworkGrid(
                        homeworkid,
                        studentid
                    );
                    console.log("НОВАЯ пришедшая grid", GridStudentRepo);
                    setPuzzles(GridStudentRepo);
                } else {
                    const homeworkStatRepo = await GetHomeworkGrid(homeworkid);
                    console.log("НОВАЯ статистика Домашки для тренера", homeworkStatRepo);
                    setPuzzles(homeworkStatRepo);
                }
            } catch (error) {
                // Обработка ошибок
                if (error.message.includes("Ошибка")) {
                    setNotification(error.message);
                } else {
                    setNotification("Произошла ошибка. Пожалуйста, попробуйте позже.");
                }
                console.error("Ошибка при загрузке домашнего задания:", error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // if (action === "select") {
        //   setShowAddModal(true);
        // }
    }, [owner, user.id, studentid, action]);

    // Функция для определения класса попыток
    const getAttemptClass = (attempt) => {
        if (attempt === null || attempt === undefined) {
            return "attempt neutral";
        }
        switch (attempt) {
            case 1:
                return "attempt green";
            case 2:
                return "attempt yellow";
            default:
                return "attempt red";
        }
    };

    // Функция для вычисления процента
    const getPercentage = (count) => {
        if (!aggregateStats) return "0%";
        const totalSolved =
            aggregateStats.success + aggregateStats.normal + aggregateStats.bad;
        const total = aggregateStats.puzzlecount;
        if (total === 0) return "0%";
        return `${((count / total) * 100).toFixed(1)}%`;
    };

    if (loading) {
        return <div className="loading">Загрузка...</div>;
    }

    return (
        <div className="homework-container">
            <h1 className="homework-title">Домашнее задание: {homeworktheme}</h1>

            {aggregateStats && (
                <>
                    {/* Карточка Общей Статистики */}
                    <Grid container spacing={2} className="stats-grid">
                        <Grid item xs={12} sm={6} md={3}>
                            <Card className="stat-card total">
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Всего задач
                                    </Typography>
                                    <Tooltip title="Общее количество задач">
                                        <AssignmentIcon style={{ fontSize: 30 }} />
                                    </Tooltip>
                                    <Typography variant="h4">
                                        {aggregateStats.puzzlecount}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Остальные карточки статистики */}
                        <Grid item xs={12} sm={6} md={3}>
                            <Card className="stat-card success">
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Успешно
                                    </Typography>
                                    <Tooltip title="Решены без ошибок">
                                        <CheckCircleIcon style={{ fontSize: 30 }} />
                                    </Tooltip>
                                    <Typography variant="h4">
                                        {aggregateStats.success} (
                                        {getPercentage(aggregateStats.success)})
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card className="stat-card normal">
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Нормально
                                    </Typography>
                                    <Tooltip title="Решены с небольшими ошибками">
                                        <InfoIcon style={{ fontSize: 30 }} />
                                    </Tooltip>
                                    <Typography variant="h4">
                                        {aggregateStats.normal} (
                                        {getPercentage(aggregateStats.normal)})
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card className="stat-card bad">
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Плохо
                                    </Typography>
                                    <Tooltip title="Решены с большими ошибками">
                                        <WarningIcon style={{ fontSize: 30 }} />
                                    </Tooltip>
                                    <Typography variant="h4">
                                        {aggregateStats.bad} ({getPercentage(aggregateStats.bad)})
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card className="stat-card not-solved">
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Не решено
                                    </Typography>
                                    <Tooltip title="Не решены">
                                        <ErrorIcon style={{ fontSize: 30 }} />
                                    </Tooltip>
                                    <Typography variant="h4">
                                        {aggregateStats.puzzlecount -
                                            aggregateStats.bad -
                                            aggregateStats.normal -
                                            aggregateStats.success}{" "}
                                        (
                                        {getPercentage(
                                            aggregateStats.puzzlecount -
                                            aggregateStats.bad -
                                            aggregateStats.normal -
                                            aggregateStats.success
                                        )}
                                        )
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}

            <div className="puzzle-grid">
                {puzzles.length === 0 ? (
                    <div className="no-puzzles">Нет задачек в этой теме</div>
                ) : (
                    puzzles.map((item) => (
                        <div
                            key={item.puzzleid}
                            className="puzzle-item"
                            onClick={() => {
                                navigate(
                                    `/homework/${homeworktheme}/${homeworkid}/${item.puzzleNumber}`
                                );
                            }}
                        >
                            <div className="puzzle-header">
                                <div className="puzzle-id">Задачка #{item.puzzleNumber}</div>
                                {showDetails && (
                                    <div
                                        className="attempt-icon"
                                        title={
                                            item.attempt !== null && item.attempt !== undefined
                                                ? `Попытки: ${item.attempt}`
                                                : "Нет данных о попытках"
                                        }
                                    >
                                        <span className={getAttemptClass(item.attempt)}></span>
                                    </div>
                                )}
                            </div>
                            <Chessboard
                                position={item.fen ? item.fen : ""}
                                arePiecesDraggable={false}
                            // boardWidth={280}q
                            />
                            {showDetails && (
                                <div className="puzzle-details">
                                    <p>
                                        <strong>Решена:</strong> {item.isSolved ? "Да" : "Нет"}
                                    </p>
                                    <p>
                                        <strong>Время:</strong>{" "}
                                        {item.timevalue !== null && item.timevalue !== undefined
                                            ? ` ${item.timevalue / 1000} сек`
                                            : "Нет данных"}
                                    </p>
                                    <p>
                                        <strong>Попытки:</strong>{" "}
                                        {item.attempt !== null && item.attempt !== undefined
                                            ? item.attempt
                                            : "Нет данных"}
                                    </p>
                                </div>
                            )}
                            {showDetails && user.role == "coach" && (
                                <button
                                    className="analyze-button"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Предотвращаем срабатывание родительского onClick
                                        navigate(
                                            `/analysis/${item.puzzleid}/${studentid}`
                                        );
                                    }}
                                    style={{
                                        marginTop: '10px',
                                        padding: '8px 12px',
                                        backgroundColor: '#4CAF50',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Анализ
                                </button>
                            )}
                        </div>
                    ))
                )}
            </div>

            {notification && (
                <Notification
                    message={notification}
                    onClose={() => setNotification(null)}
                />
            )}
            {showAddModal && (
                <AddHomeworkModal
                    onClose={() => setShowAddModal(false)}
                    owner={owner}
                    homework={homeworktheme}
                    action={action}
                />
            )}
        </div>
    );
};

export default HomeworkGrid;
