// CoachDashboard.tsx
//@ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as api from "../Utils/API/API";
import { UserContext } from "./UserContext";
import { CreateHomework, GetHomework } from "../Utils/API/Homework";
import CoachModal from "./Modals/CoachModal";
import { Logout } from "../Utils/API/Login";
import { AddTrainee } from "../Utils/API/Coach";
import StudentsModal from "./Modals/StudentModal";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
    Snackbar,
    Alert,
    Box,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    useTheme,
    useMediaQuery,
    Fade,
    Drawer,
    ListItemButton,
} from "@mui/material";
import {
    Menu as MenuIcon,
    Add as AddIcon,
    PersonAdd as PersonAddIcon,
    Assignment as AssignmentIcon,
    Visibility as VisibilityIcon,
    ExitToApp as ExitToAppIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CoachDashboard: React.FC = () => {
    const [inviteLink, setInviteLink] = useState<string>("");
    const [downloadLink, setDownloadLink] = useState<boolean>(false);
    const [homeworks, setHomeworks] = useState<any[]>([]);
    const [theme, setTheme] = useState<string>("");
    const [lichessName, setLichessName] = useState<string>("");
    const [notification, setNotification] = useState<{
        message: string;
        severity: "success" | "error";
    } | null>(null);
    const [allLinks, setAllLinks] = useState<string[]>([]);
    const [showAllLinks, setShowAllLinks] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const userContext = useContext(UserContext);
    if (!userContext) {
        throw new Error("Не найден userContext");
    }
    const { user, setUser } = userContext;

    const [isHomeworkModalOpen, setIsHomeworkModalOpen] =
        useState<boolean>(false);
    const [isStudentsModalOpen, setIsStudentsModalOpen] =
        useState<boolean>(false);

    const themeMUI = useTheme();
    const isMobile = useMediaQuery(themeMUI.breakpoints.down("sm"));

    // Обработчик выхода из системы
    const handleLogout = async () => {
        try {
            await Logout();
            setUser((prev) => ({ ...prev, isLogged: false, id: 0 }));
            navigate("/");
        } catch (err) {
            setNotification({
                message: "Ошибка при выходе из системы.",
                severity: "error",
            });
            console.error("Ошибка при выходе:", err);
        }
    };

    // Открытие модального окна домашних заданий и загрузка данных
    const openHomeworkModal = async () => {
        try {
            const response = await GetHomework();
            if (response.status === 200) {
                setHomeworks(response.data);
                setIsHomeworkModalOpen(true);
                console.log("Домашние задания", response.data);
            } else {
                setNotification({ message: response.data.message, severity: "error" });
            }
        } catch (err) {
            setNotification({
                message: "Ошибка при загрузке домашних заданий.",
                severity: "error",
            });
            console.error("Ошибка при загрузке домашних заданий:", err);
        }
    };

    // Закрытие модального окна домашних заданий
    const closeHomeworkModal = () => {
        setIsHomeworkModalOpen(false);
    };

    // Создание домашнего задания
    const handleAddHomework = async (e: React.FormEvent) => {
        e.preventDefault();

        const homeworkData = {
            Theme: theme,
            Owner: user.id,
        };

        try {
            const response = await CreateHomework(homeworkData);
            if (response.status === 200) {
                setNotification({
                    message: "Домашнее задание успешно создано.",
                    severity: "success",
                });
                setTheme("");
                if (isHomeworkModalOpen) {
                    setHomeworks((prev) => [...prev, response.data]);
                }
            } else {
                setNotification({ message: response.data.message, severity: "error" });
            }
            console.log("Тема создана", response);
        } catch (error: any) {
            if (error.message.includes("Ошибка")) {
                setNotification({ message: error.message, severity: "error" });
            } else {
                setNotification({
                    message: "Произошла ошибка. Пожалуйста, попробуйте позже.",
                    severity: "error",
                });
            }
            console.error("Ошибка при добавлении домашнего задания:", error.message);
        }
    };

    // Добавление ученика по имени Lichess
    const handleAddTrainee = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!lichessName.trim()) {
            setNotification({
                message: "Имя Lichess не может быть пустым.",
                severity: "error",
            });
            return;
        }

        try {
            const response = await AddTrainee(lichessName.trim());
            if (response.status === 200) {
                setNotification({
                    message: "Ученик успешно добавлен.",
                    severity: "success",
                });
                setLichessName("");
            } else {
                setNotification({ message: response.data.message, severity: "error" });
            }
            console.log("Информация о добавлении студента", response);
        } catch (error: any) {
            setNotification({
                message: `Произошла ошибка при добавлении ученика, ${error}`,
                severity: "error",
            });
            console.error("Ошибка при добавлении ученика:", error);
        }
    };

    // Генерация ссылки приглашения
    const generateInviteLink = async () => {
        try {
            const response = await axios.get(
                `${api.protocol}://${api.backendHost}/api/generatelink`,
                {
                    withCredentials: true,
                }
            );
            setInviteLink(response.data.link);
            setDownloadLink(true);
            setNotification({
                message: "Ссылка успешно сгенерирована.",
                severity: "success",
            });
        } catch (error) {
            setNotification({
                message: "Ошибка при генерации ссылки.",
                severity: "error",
            });
            console.error("Ошибка при генерации ссылки:", error);
        }
    };

    // Загрузка всех ссылок тренера
    const loadAllLinks = async () => {
        try {
            const response = await axios.get(
                `${api.protocol}://${api.backendHost}/api/coach/coachlink`,
                {
                    withCredentials: true,
                }
            );
            console.log("ссылки", response);
            if (response.status === 200) {
                setAllLinks(response.data);
                setShowAllLinks(true);
                setNotification({
                    message: "Ссылки успешно загружены.",
                    severity: "success",
                });
            } else {
                setNotification({
                    message: "Ошибка при загрузке ссылок.",
                    severity: "error",
                });
            }
        } catch (error) {
            setNotification({
                message: "Произошла ошибка при загрузке ссылок.",
                severity: "error",
            });
            console.error("Ошибка при загрузке ссылок:", error);
        }
    };

    useEffect(() => {
        if (inviteLink !== "") {
            setDownloadLink(true);
        }
    }, [inviteLink]);

    // Функция для открытия/закрытия бокового меню
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    // Меню навигации
    const navigationMenu = (
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/coachdashboard")}>
                        <ListItemText primary="Главная" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={openHomeworkModal}>
                        <ListItemText primary="Домашние Задания" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => setIsStudentsModalOpen(true)}>
                        <ListItemText primary="Мои Ученики" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemText primary="Выйти" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    {isMobile && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Личный Кабинет Тренера
                    </Typography>
                    {!isMobile && (
                        <>
                            <Button color="inherit" onClick={openHomeworkModal}>
                                Домашние Задания
                            </Button>
                            <Button
                                color="inherit"
                                onClick={() => setIsStudentsModalOpen(true)}
                            >
                                Мои Ученики
                            </Button>
                            <Button color="inherit" onClick={handleLogout}>
                                Выйти
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            {/* Боковое меню для мобильных устройств */}
            {isMobile && (
                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    {navigationMenu}
                </Drawer>
            )}

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Добавить Домашнее Задание */}
                    <Grid item xs={12} md={6} lg={4}>
                        <Fade in timeout={500}>
                            <Card
                                sx={{
                                    p: 2,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Добавить Домашнее Задание
                                    </Typography>
                                    <form onSubmit={handleAddHomework}>
                                        <TextField
                                            label="Тема"
                                            variant="outlined"
                                            fullWidth
                                            value={theme}
                                            onChange={(e) => setTheme(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                        />
                                        <Tooltip title="Добавить новое домашнее задание" arrow>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                fullWidth
                                            >
                                                Добавить
                                            </Button>
                                        </Tooltip>
                                    </form>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>

                    {/* Генерация Ссылки Приглашения */}
                    <Grid item xs={12} md={6} lg={4}>
                        <Fade in timeout={700}>
                            <Card
                                sx={{
                                    p: 2,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Пригласить Ученика
                                    </Typography>
                                    <Tooltip
                                        title="Сгенерировать ссылку для приглашения ученика"
                                        arrow
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<PersonAddIcon />}
                                            onClick={generateInviteLink}
                                            fullWidth
                                            sx={{ mb: 2 }}
                                        >
                                            Сгенерировать Ссылку
                                        </Button>
                                    </Tooltip>
                                    {downloadLink && (
                                        <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                                            Ссылка для приглашения:{" "}
                                            <a
                                                href={inviteLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {inviteLink}
                                            </a>
                                        </Typography>
                                    )}
                                    <Tooltip
                                        title="Показать все ранее сгенерированные ссылки"
                                        arrow
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<LinkIcon />}
                                            onClick={loadAllLinks}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                        >
                                            Показать все ссылки
                                        </Button>
                                    </Tooltip>
                                    {showAllLinks && (
                                        <List sx={{ mt: 2, maxHeight: 200, overflowY: "auto" }}>
                                            {allLinks.map((link, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        primary={
                                                            <a
                                                                href={link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {link}
                                                            </a>
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    )}
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>

                    {/* Добавить Ученика */}
                    <Grid item xs={12} md={6} lg={4}>
                        <Fade in timeout={900}>
                            <Card
                                sx={{
                                    p: 2,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Добавить Ученика
                                    </Typography>
                                    <form onSubmit={handleAddTrainee}>
                                        <TextField
                                            label="Имя Lichess"
                                            variant="outlined"
                                            fullWidth
                                            value={lichessName}
                                            onChange={(e) => setLichessName(e.target.value)}
                                            required
                                            sx={{ mb: 2 }}
                                        />
                                        <Tooltip title="Добавить ученика по имени Lichess" arrow>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<PersonAddIcon />}
                                                fullWidth
                                            >
                                                Добавить
                                            </Button>
                                        </Tooltip>
                                    </form>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>

                    {/* Просмотр Домашних Заданий */}
                    <Grid item xs={12} md={6} lg={4}>
                        <Fade in timeout={1100}>
                            <Card
                                sx={{
                                    p: 2,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Мои Домашние Задания
                                    </Typography>
                                    <Tooltip title="Просмотреть ваши домашние задания" arrow>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AssignmentIcon />}
                                            onClick={openHomeworkModal}
                                            fullWidth
                                        >
                                            Просмотреть
                                        </Button>
                                    </Tooltip>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>

                    {/* Составить Домашнее Задание */}
                    <Grid item xs={12} md={6} lg={4}>
                        <Fade in timeout={1300}>
                            <Card
                                sx={{
                                    p: 2,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Составить Домашнее Задание
                                    </Typography>
                                    <Tooltip title="Перейти к созданию домашнего задания" arrow>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AssignmentIcon />}
                                            onClick={() => {
                                                navigate("/generatehomework");
                                            }}
                                            fullWidth
                                        >
                                            Создать
                                        </Button>
                                    </Tooltip>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>

                    {/* Мои Ученики */}
                    <Grid item xs={12} md={6} lg={4}>
                        <Fade in timeout={1500}>
                            <Card
                                sx={{
                                    p: 2,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Мои Ученики
                                    </Typography>
                                    <Tooltip title="Просмотреть список ваших учеников" arrow>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<VisibilityIcon />}
                                            onClick={() => setIsStudentsModalOpen(true)}
                                            fullWidth
                                        >
                                            Показать
                                        </Button>
                                    </Tooltip>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>
                </Grid>

                {/* Уведомления */}
                {notification && (
                    <Snackbar
                        open={Boolean(notification)}
                        autoHideDuration={6000}
                        onClose={() => setNotification(null)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    >
                        <Alert
                            onClose={() => setNotification(null)}
                            severity={notification.severity}
                            sx={{ width: "100%" }}
                        >
                            {notification.message}
                        </Alert>
                    </Snackbar>
                )}

                {/* Модальное Окно Домашних Заданий */}
                <CoachModal
                    isOpen={isHomeworkModalOpen}
                    closeModal={closeHomeworkModal}
                    homeworks={homeworks}
                />

                {/* Модальное Окно Ученики */}
                {isStudentsModalOpen && (
                    <StudentsModal onClose={() => setIsStudentsModalOpen(false)} />
                )}
            </Container>
        </>
    );
};

export default CoachDashboard;
