// @ts-nocheck
import { useState, useCallback, useEffect, useContext } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import axios from "axios";
import "../Styles/ChessBoard.css";
import { themes, lichessTheme, findKeyByValue } from "../Utils/Themes";
import Modal from "./Modals/Modals";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import * as api from "../Utils/API/API";
import CoachSideBar from "./Modals/CoachSideBar";
import { setStatistic } from "../Utils/API/statistic";

function Puzzle() {
    const { user, setUser } = useContext(UserContext); // информация о юзере
    const [puzzle, setPuzzle] = useState("");
    const [currentMoveIndex, setCurrentMoveIndex] = useState(1);
    const [allMoveInfo, setMoveInfo] = useState({
        move: "",
        currentIndex: 0,
        isRigth: "",
    });

    const [fen, setFen] = useState("");
    const [solution, setSolution] = useState("");
    const [chess, setChess] = useState("");
    const [orientation, setOrientation] = useState("white");
    const [isLoading, setIsLoading] = useState(true); // состояние загрузки
    const [timeStart, setTime] = useState(new Date());
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);

    let { puzzleId, themes, min, max } = useParams();
    const makeAMove = useCallback(
        (move) => {
            try {
                const result = chess.move(move);
                setFen(chess.fen());
                return result;
            } catch (error) {
                return null; // Возвращаем null, если ход был недействительным
            }
        },
        [chess]
    );

    useEffect(() => {
        setCurrentMoveIndex(1);
        setMoveInfo((prev) => ({
            ...prev,
            move: "",
            currentIndex: 0,
        }));
        setIsLoading(true);
        axios
            .get(
                `${api.protocol}://${api.backendHost}/api/puzzle/${themes}/${puzzleId}/${min}/${max}`,
                {
                    withCredentials: true,
                }
            )
            .then((resp) => {
                if (resp && resp.data) {
                    if (resp.data.puzzleid != puzzleId) {
                        navigate(`/puzzle/${themes}/${resp.data.puzzleid}/${min}/${max}`);
                    }
                    console.log("Информация о пазле", resp, min, max);
                    setPuzzle(resp.data);
                    setSolution(resp.data.solution);
                    const parts = resp.data.fen.split(" ");
                    const player = parts[1]; // Получаем текущего игрока (w или b)
                    setOrientation(player === "w" ? "black" : "white");
                    setIsLoading(false);
                    setTime(new Date());
                }
            })
            .catch((error) => {
                if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error("Ошибка запроса:", error.request);
                } else {
                    // Что-то произошло при настройке запроса, вызвавшее ошибку
                    console.error("Ошибка:", error.message);
                }
            });
    }, [puzzleId, themes, min, max]);

    useEffect(() => {
        if (puzzle && isLoading === false) {
            const newChess = new Chess(puzzle.fen);
            setChess(newChess);
            setFen(newChess.fen());

            if (solution.length > 0) {
                newChess.move(solution[0]);
                setFen(newChess.fen());
                // setCurrentMoveIndex((a) => a + 1);
            }
        }
    }, [isLoading, puzzle, solution]); // Зависимости

    function puzzleSolved() {
        setModalOpen(true);
        const differenceInMillis = new Date() - timeStart;
        const differenceInSeconds = Math.floor(differenceInMillis / 1000);
        const minutes = Math.floor(differenceInSeconds / 60);
        const seconds = differenceInSeconds % 60;
        console.log(`Разница: ${minutes} минут(ы) и ${seconds} секунд(ы)`);
    }

    function opponentMove() {
        const nextMove = solution[currentMoveIndex + 1];
        chess.move(nextMove);
        setFen(chess.fen());
        setCurrentMoveIndex((a) => a + 1);
    }
    useEffect(() => {
        if (allMoveInfo.move.san !== undefined && user.role !== "anonimous") {
            setStatistic(user, puzzleId, allMoveInfo, timeStart);
            console.log("Информация о ходе", allMoveInfo);
            setTime(new Date());
        }
    }, [allMoveInfo]);

    useEffect(() => {
        console.log("номер хода", currentMoveIndex);
    }, [currentMoveIndex]);

    function onDrop(sourceSquare, targetSquare) {
        const moveData = {
            from: sourceSquare,
            to: targetSquare,
            color: chess.turn(),
        };

        const move = makeAMove(moveData);

        if (move != null) {
            // Проверка, является ли ход правильным
            if (move.lan === solution[currentMoveIndex]) {
                setMoveInfo((prevState) => ({
                    ...prevState,
                    move: move,
                    currentIndex: prevState.currentIndex + 1,
                    isRigth: true,
                }));
                setCurrentMoveIndex((a) => a + 1); // Переход к следующему ходу
                // Если еще есть ходы в решении, выполняем следующий ход
                if (currentMoveIndex < solution.length - 1) {
                    opponentMove();
                } else {
                    puzzleSolved();
                }
                return true; // Ход был правильным
            } else {
                setMoveInfo((prevState) => ({
                    ...prevState,
                    move: move,
                    isRigth: false,
                }));
                // Возврат к предыдущей позиции
                chess.undo();
                setFen(chess.fen());
                return false; // Ход был неверным
            }
        }
        {
            return false;
        }
    }
    const handleNextTask = async () => {
        try {
            const response = await axios.get(
                `${api.protocol}://${api.backendHost}/api/puzzle/next/${themes}/${puzzleId}/${min}/${max}`
            );
            navigate(`/puzzle/${themes}/${response.data}/${min}/${max}`);
        } catch (error) {
            console.error("Ошибка", error);
        }
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleShowModal = () => {
        setModalOpen(true);
    };
    const handleAddTask = (theme) => {
        console.log(`Задача добавлена в тему: ${theme}`);
        // Логика для добавления задачи в выбранную тему
    };
    // Game component returned jsx
    return (
        <>
            <div className="parent">
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    handleNextTask={handleNextTask}
                />
                <div className="board">
                    <Chessboard
                        position={fen}
                        onPieceDrop={onDrop}
                        boardOrientation={orientation}
                    />
                </div>
            </div>
            <div className="task-container">
                <div className="task">
                    Текущая задача {puzzleId}, рейтинг задачи {puzzle.rating}
                </div>
                <div className="task">
                    Текущая тема {findKeyByValue(lichessTheme, themes)}
                </div>
                {/* Кнопка для перехода к следующей задаче */}
                <button className="next-task-button" onClick={handleNextTask}>
                    Следующая задача
                </button>
            </div>
            {/* <CoachSideBar puzzleId={puzzle.puzzleid} /> */}
        </>
    );
}

export default Puzzle;
