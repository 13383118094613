const sv = {
    "buttons:flipper": "Vänd sidorna av styrelsen",
    "buttons:first": "Gå till första steget",
    "buttons:prev": "Gå till föregående drag",
    "buttons:next": "Gå till nästa steg",
    "buttons:play": "Play / sluta spela alla drag",
    "buttons:last": "Gå till det senaste drag",
    "buttons:deleteVar": "Radera variation",
    "buttons:promoteVar": "Främja variation",
    "buttons:deleteMoves": "Radera vila flyttar härifrån",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Visning PGN av aktuella spelet",
    "buttons:hidePGN": "Dölja visas PGN",
    "buttons:getHint": "Ge ett tips",
    "buttons:makeMove": "Visa nästa drag",
    "buttons:showSolution": "Visa hela lösningen",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "T",
    "chess:b": "L",
    "chess:n": "S",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "T",
    "chess:B": "L",
    "chess:N": "S"
};
export default sv;
