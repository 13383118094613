import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Slider,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GenerateCustomHomework } from "../Utils/API/Homework";
import { themes, lichessTheme } from "../Utils/Themes";
import { UserContext } from "./UserContext";

const GenerateHomework = () => {
  const navigate = useNavigate();

  // Состояния формы
  const [assignmentName, setAssignmentName] = useState("");
  const [theme, setTheme] = useState("");
  const [numTasks, setNumTasks] = useState(30);
  const [ratingRange, setRatingRange] = useState([500, 2500]);
  const { user, setUser } = useContext(UserContext); // Информация о юзере

  // Состояния ошибок
  const [nameError, setNameError] = useState(false);

  // Обработчики изменений
  const handleNameChange = (e) => {
    setAssignmentName(e.target.value);
    if (e.target.value.trim() !== "") {
      setNameError(false);
    }
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  const handleNumTasksChange = (e) => {
    setNumTasks(e.target.value);
  };

  const handleRatingChange = (e, newValue) => {
    setRatingRange(newValue);
  };

  // Обработчик отправки формы
  const handleGenerate = async () => {
    // Валидация названия задания
    if (assignmentName.trim() === "") {
      setNameError(true);
      return;
    }
    const englishTheme = lichessTheme[theme];

    // Подготовка данных для API
    const data = {
      assignmentName: assignmentName.trim(),
      englishTheme,
      numTasks,
      ratingRange,
    };

    try {
      // Отправка данных через API
      // Замените URL на ваш реальный API эндпоинт
      const response = await GenerateCustomHomework(
        assignmentName,
        englishTheme,
        numTasks,
        ratingRange[0],
        ratingRange[1]
      );
      console.log(response.data, data);
      // Перенаправление на страницу сгенерированных заданий
      // Замените '/generated-homework' на ваш реальный путь
      navigate(`/homeworkgrid/${assignmentName}/${response.data}`);
    } catch (error) {
      console.error("Ошибка при генерации заданий:", error);
      // Здесь можно добавить уведомление пользователю об ошибке
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "0 auto",
        padding: 4,
        display: "flex",
        flexDirection: "column",
        gap: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Создать Домашнее Задание
      </Typography>

      {/* Ввод названия задания */}
      <TextField
        label="Название домашнего задания"
        variant="outlined"
        value={assignmentName}
        onChange={handleNameChange}
        error={nameError}
        helperText={nameError ? "Название не должно быть пустым" : ""}
        fullWidth
        required
      />

      {/* Выбор темы */}
      <FormControl fullWidth>
        <InputLabel id="theme-label">Тема</InputLabel>
        <Select
          labelId="theme-label"
          value={theme}
          label="Тема"
          onChange={handleThemeChange}
        >
          {themes.map((themeItem, index) => (
            <MenuItem key={index} value={themeItem}>
              {themeItem}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Выбор количества задач */}
      <FormControl fullWidth>
        <InputLabel id="num-tasks-label">Количество задач</InputLabel>
        <Select
          labelId="num-tasks-label"
          value={numTasks}
          label="Количество задач"
          onChange={handleNumTasksChange}
        >
          {Array.from({ length: 71 }, (_, i) => 30 + i).map((num) => (
            <MenuItem key={num} value={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Настройка диапазона рейтинга */}
      <Box>
        <Typography gutterBottom>Диапазон рейтинга</Typography>
        <Slider
          value={ratingRange}
          onChange={handleRatingChange}
          valueLabelDisplay="auto"
          min={500}
          max={2500}
          step={50}
        />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">{ratingRange[0]}</Typography>
          <Typography variant="body2">{ratingRange[1]}</Typography>
        </Box>
      </Box>

      {/* Кнопка генерации */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerate}
        size="large"
      >
        Сгенерировать
      </Button>
    </Box>
  );
};

export default GenerateHomework;
