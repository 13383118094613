import React from "react";
import HomePage from "./HomePage";
import { Routes, Route, Link } from "react-router-dom";
import Puzzle from "./Puzzle";
import Header from "./Header";
import Tactic from "./Tactic";
import { UserProvider } from "./UserContext";
import CoachDashboard from "./CoashDashboard";
import HomeworkPuzzles from "./HomeworkPuzzles";
import StudentProfile from "./StudentProfile";
import HomeworkGrid from "./HomeworkGrid";
import GenerateHomework from "./GenerateHomework";
import { PGNViewer } from "./Analysis/ChessBoard";
import { ChessPuzzle } from "./Analysis/Chesspuzzle";
import { Chess } from "chess.js";

const App = () => {
    return (

        <UserProvider>
            <div>
                <header>{<Header />}</header>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/puzzles" element={<ChessPuzzle />} />
                    <Route path="/analysis/:puzzleid/:studentid" element={<PGNViewer />} />
                    <Route path="/puzzle/:themes/:puzzleId" element={<Puzzle />} />
                    <Route
                        path="/puzzle/:themes/:puzzleId/:min/:max"
                        element={<Puzzle />}
                    />
                    <Route
                        path="/homework/:homeworktheme/:homeworkid/:puzzleId"
                        element={<HomeworkPuzzles />}
                    />
                    <Route path="/tactic" element={<Tactic />} />
                    <Route path="/coachdashboard" element={<CoachDashboard />} />
                    <Route path="/studentprofile" element={<StudentProfile />} />
                    <Route
                        path="/studentprofile/:studentid"
                        element={<StudentProfile />}
                    />
                    <Route
                        path="/homeworkgrid/:homeworktheme/:homeworkid"
                        element={<HomeworkGrid />}
                    />
                    <Route
                        path="/homeworkgrid/:homeworktheme/:homeworkid/action/:action"
                        element={<HomeworkGrid />}
                    />
                    <Route
                        path="/homeworkgrid/:homeworktheme/:homeworkid/studentid/:studentid"
                        element={<HomeworkGrid />}
                    />
                    <Route path="/generatehomework" element={<GenerateHomework />} />
                </Routes>
            </div>
        </UserProvider>
    );
};

export default App;
