// @ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    CardActions,
    Snackbar,
    Alert,
    Box,
    Badge,
    Tooltip,
    Modal,
    TextField,
    LinearProgress,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Logout } from "../Utils/API/Login";
import { UserContext } from "./UserContext";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css"; // Убедитесь, что стили подключены
import "../Styles/StudentProfile.css";
import Notification from "./Modals/Notification";
import { addCoachByToken } from "../Utils/API/Coach";
import { GetUserInfo, GetUserInfoById } from "../Utils/API/UserInfo";
import { puzzleSolved } from "../Utils/API/statistic";
import {
    GetStudentHomeworkRepo,
    GetStudentHomeworkRepoById,
} from "../Utils/API/HomeworkRepository";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ErrorIcon from "@mui/icons-material/Error";

const StudentProfile = () => {
    const [homeworks, setHomeworks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const { user, setUser } = useContext(UserContext);
    const [userInfo, setUserInfo] = useState({ lichessname: "" });
    const [dateValue, setdateValue] = useState([]);
    const navigate = useNavigate();
    const { studentid } = useParams();
    const [tooltip, setTooltip] = useState({
        visible: false,
        x: 0,
        y: 0,
        content: "",
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleMouseOver = (event, value) => {
        const { clientX, clientY } = event;
        if (value) {
            setTooltip({
                visible: true,
                x: clientX,
                y: clientY,
                content: `Дата: ${value.date}, Решено задач: ${value.count}`,
            });
        } else {
            setTooltip({
                visible: true,
                x: clientX,
                y: clientY,
                content: "Нет данных для этой даты",
            });
        }
    };

    const handleMouseOut = () => {
        setTooltip({ visible: false, x: 0, y: 0, content: "" });
    };

    // Состояние для модального окна добавления тренера
    const [openModal, setOpenModal] = useState(false);
    const [token, setToken] = useState("");

    const handleLogout = async () => {
        try {
            await Logout();
            setUser((prev) => ({ ...prev, isLogged: false }));
            navigate("/");
        } catch (err) {
            setSnackbarMessage("Ошибка при выходе из системы.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            console.error("Ошибка при выходе:", err);
        }
    };

    useEffect(() => {
        const fetchHomeworks = async () => {
            try {
                console.log("id stud", studentid);
                if (studentid === undefined) {
                    const resp1 = await GetStudentHomeworkRepo();
                    setHomeworks(resp1);
                    const resp = await puzzleSolved(user.id);
                    console.log("Домашки ученика НОВЫЕ", resp1);
                    setdateValue(resp.data);
                    console.log("github", resp.data);
                } else {
                    const resp1 = await GetStudentHomeworkRepoById(studentid);
                    setHomeworks(resp1);
                    const resp = await puzzleSolved(studentid);
                    console.log("github", resp.data);
                    setdateValue(resp.data);
                    console.log("Домашки ученика НОВЫЕ", resp1);
                }
            } catch (err) {
                setError("Ошибка при загрузке данных");
                setSnackbarMessage("Ошибка при загрузке данных.");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                console.error("Ошибка при загрузке данных:", err);
            } finally {
                setLoading(false);
            }
        };

        const fetchUser = async () => {
            try {
                if (studentid === undefined) {
                    const response = await GetUserInfo();
                    setUserInfo(response);
                    console.log(response);
                } else {
                    const response = await GetUserInfoById(studentid);
                    setUserInfo(response);
                    console.log(response);
                }
            } catch (err) {
                setError("Ошибка при загрузке данных о пользователе");
                setSnackbarMessage("Ошибка при загрузке данных о пользователе.");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        };

        fetchHomeworks();
        fetchUser();
    }, [studentid]);

    // Функция для расчёта средней затраченной времени на задания
    const calculateAverageTime = () => {
        const completedHomeworks = homeworks.filter(
            (hw) => hw.completed && hw.timeSpent > 0
        );
        if (completedHomeworks.length === 0) return 0;
        const totalTime = completedHomeworks.reduce(
            (acc, hw) => acc + hw.timeSpent,
            0
        );
        return Math.round(totalTime / completedHomeworks.length);
    };

    // Обработчики для модального окна
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleTokenSubmit = async () => {
        if (token) {
            // Логика проверки и отправки токена на сервер
            console.log("Токен для добавления тренера:", token);
            try {
                const response = await addCoachByToken(token);
                setSnackbarMessage("Токен отправлен для проверки");
                console.log(response.data);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                handleCloseModal();
            } catch (error) {
                setSnackbarMessage("Ошибка при отправке токена.");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                console.error("Ошибка при отправке токена:", error);
            }
        } else {
            setSnackbarMessage("Пожалуйста, введите токен.");
            setSnackbarSeverity("warning");
            setOpenSnackbar(true);
        }
    };

    if (loading)
        return (
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 10,
                }}
            >
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Загрузка...
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ExitToAppIcon />}
                    sx={{ mt: 4 }}
                    onClick={handleLogout}
                >
                    Выйти
                </Button>
            </Container>
        );

    if (error)
        return (
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 10,
                }}
            >
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ExitToAppIcon />}
                    sx={{ mt: 2 }}
                    onClick={handleLogout}
                >
                    Выйти
                </Button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity={snackbarSeverity}
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        );

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Информация о студенте */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Card sx={{ p: 2, textAlign: "center" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Avatar
                                    alt={userInfo.lichessname}
                                    src={user.avatarUrl || "/default-avatar.png"}
                                    sx={{ width: 100, height: 100, mb: 2 }}
                                />
                                <Typography variant="h6">{userInfo.lichessname}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {user.email}
                                </Typography>
                                <Box sx={{ mt: 2, width: "100%" }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Прогресс выполнения:
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box sx={{ width: "100%", mr: 1 }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={user.progress || 0}
                                                sx={{
                                                    height: 10,
                                                    borderRadius: 5,
                                                    backgroundColor: "#e0e0e0",
                                                    "& .MuiLinearProgress-bar": {
                                                        backgroundColor:
                                                            (user.progress || 0) > 50
                                                                ? "success.main"
                                                                : "warning.main",
                                                        transition: "width 0.5s",
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                {`${user.progress || 0}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <CardActions
                                sx={{
                                    justifyContent: "center",
                                    mt: 2,
                                    flexDirection: isMobile ? "column" : "row",
                                    gap: 1,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ExitToAppIcon />}
                                    onClick={handleLogout}
                                    fullWidth={isMobile}
                                >
                                    Выйти
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleOpenModal}
                                    fullWidth={isMobile}
                                >
                                    Добавить тренера
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Домашние задания и статистика */}
                    <Grid item xs={12} md={8} lg={9}>
                        <Grid container spacing={3}>
                            {/* Общая статистика */}
                            <Grid item xs={12}>
                                <Card sx={{ p: 3, backgroundColor: "#f5f5f5" }}>
                                    <Typography variant="h5" gutterBottom>
                                        Общая статистика
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            <Box sx={{ textAlign: "center" }}>
                                                <AssignmentIcon color="action" sx={{ fontSize: 40 }} />
                                                <Typography variant="subtitle1">
                                                    Всего заданий
                                                </Typography>
                                                <Typography variant="h4">{homeworks.length}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box sx={{ textAlign: "center" }}>
                                                <CheckCircleIcon
                                                    color="success"
                                                    sx={{ fontSize: 40 }}
                                                />
                                                <Typography variant="subtitle1">Выполнено</Typography>
                                                <Typography variant="h4" color="success.main">
                                                    {homeworks.filter((hw) => hw.isSolved).length}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box sx={{ textAlign: "center" }}>
                                                <HourglassEmptyIcon
                                                    color="error"
                                                    sx={{ fontSize: 40 }}
                                                />
                                                <Typography variant="subtitle1">
                                                    Не выполнено
                                                </Typography>
                                                <Typography variant="h4" color="error.main">
                                                    {homeworks.filter((hw) => !hw.isSolved).length}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography variant="subtitle1">
                                                    Среднее время выполнения
                                                </Typography>
                                                <Typography variant="h4">
                                                    {calculateAverageTime()} мин
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            {/* Список домашних заданий */}
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    Домашние задания
                                </Typography>
                                {homeworks.length === 0 ? (
                                    <Typography variant="body1">
                                        Нет доступных домашних заданий.
                                    </Typography>
                                ) : (
                                    <Grid container spacing={3}>
                                        {homeworks.map(
                                            (homework) => (
                                                (homework.solvedPuzzle =
                                                    homework.bad + homework.normal + homework.success),
                                                (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        key={homework.homeworkid}
                                                    >
                                                        <Card
                                                            sx={{
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                transition: "transform 0.2s",
                                                                "&:hover": {
                                                                    transform: "scale(1.02)",
                                                                },
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Typography variant="h6" gutterBottom>
                                                                    {homework.theme}
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        mb: 1,
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <AssignmentIcon
                                                                        color="action"
                                                                        sx={{ mr: 1 }}
                                                                    />
                                                                    <Typography variant="body2" sx={{ flex: 1 }}>
                                                                        Статус:{" "}
                                                                        {homework.isSolved ? (
                                                                            <Badge
                                                                                badgeContent="Выполнено"
                                                                                color="success"
                                                                                sx={{
                                                                                    "& .MuiBadge-badge": {
                                                                                        fontSize: "0.75rem",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Домашнее задание выполнено">
                                                                                    <CheckCircleIcon color="success" />
                                                                                </Tooltip>
                                                                            </Badge>
                                                                        ) : (
                                                                            <Badge
                                                                                badgeContent=""
                                                                                color="error"
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    "& .MuiBadge-badge": {
                                                                                        fontSize: "0.75rem",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Домашнее задание не выполнено">
                                                                                    <HourglassEmptyIcon
                                                                                        color="error"
                                                                                        sx={{ mr: 0.5 }}
                                                                                    />
                                                                                </Tooltip>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="span"
                                                                                >
                                                                                    Не выполнено
                                                                                </Typography>
                                                                            </Badge>
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ mt: 2 }}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                        gutterBottom
                                                                    >
                                                                        Решено задач:{" "}
                                                                        {homework.bad +
                                                                            homework.normal +
                                                                            homework.success}{" "}
                                                                        из {homework.puzzlecount}
                                                                    </Typography>
                                                                    <LinearProgress
                                                                        variant="determinate"
                                                                        value={
                                                                            homework.puzzlecount > 0
                                                                                ? (homework.solvedPuzzle /
                                                                                    homework.puzzlecount) *
                                                                                100
                                                                                : 0
                                                                        }
                                                                        sx={{ height: 10, borderRadius: 5 }}
                                                                    />
                                                                </Box>

                                                                <Box sx={{ mt: 2 }}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                        sx={{ fontWeight: "bold" }}
                                                                    >
                                                                        Статистика по задачам:
                                                                    </Typography>

                                                                    {/* Успешно решено */}
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <CheckCircleIcon
                                                                            color="success"
                                                                            sx={{ mr: 1 }}
                                                                        />
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.secondary"
                                                                            sx={{ flex: 1 }}
                                                                        >
                                                                            {/* Успешно решено */}: {homework.success}{" "}
                                                                            задач (
                                                                            {homework.puzzlecount > 0
                                                                                ? (
                                                                                    (homework.success /
                                                                                        homework.puzzlecount) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                                : 0}
                                                                            %)
                                                                        </Typography>
                                                                    </Box>

                                                                    {/* Нормально решено */}
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <HelpOutlineIcon
                                                                            color="primary"
                                                                            sx={{ mr: 1 }}
                                                                        />
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.secondary"
                                                                            sx={{ flex: 1 }}
                                                                        >
                                                                            {/* Нормально решено */}:{" "}
                                                                            {homework.normal} задач (
                                                                            {homework.puzzlecount > 0
                                                                                ? (
                                                                                    (homework.normal /
                                                                                        homework.puzzlecount) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                                : 0}
                                                                            %)
                                                                        </Typography>
                                                                    </Box>

                                                                    {/* Плохо решено */}
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <ErrorIcon color="error" sx={{ mr: 1 }} />
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.secondary"
                                                                            sx={{ flex: 1 }}
                                                                        >
                                                                            {/* Плохо решено */}: {homework.bad} задач
                                                                            (
                                                                            {homework.puzzlecount > 0
                                                                                ? (
                                                                                    (homework.bad /
                                                                                        homework.puzzlecount) *
                                                                                    100
                                                                                ).toFixed(2)
                                                                                : 0}
                                                                            %)
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </CardContent>
                                                            <CardActions>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    component={Link}
                                                                    to={`/homeworkgrid/${homework.theme}/${homework.homeworkid
                                                                        }/studentid/${studentid !== undefined
                                                                            ? studentid
                                                                            : user.id
                                                                        }`}
                                                                    fullWidth
                                                                    startIcon={<ExitToAppIcon />}
                                                                >
                                                                    Просмотр
                                                                </Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )
                                            )
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* Календарь активности */}
            <Container
                maxWidth="lg"
                sx={{
                    mt: 4,
                    mb: 4,
                    position: "relative",
                    padding: isMobile ? "0 10px" : "0 20px",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Активность
                </Typography>
                <Box className="calendar-container" sx={{ position: "relative" }}>
                    <CalendarHeatmap
                        startDate={new Date("2024-10-01")}
                        endDate={new Date("2025-02-30")}
                        values={dateValue}
                        classForValue={(value) => {
                            if (!value || value.count < 10) {
                                return "color-empty";
                            } else if (value.count >= 10 && value.count < 20) {
                                return "color-scale-1";
                            } else if (value.count >= 20 && value.count < 30) {
                                return "color-scale-2";
                            } else if (value.count >= 30 && value.count < 40) {
                                return "color-scale-3";
                            } else {
                                return "color-scale-4";
                            }
                        }}
                        onMouseOver={(event, value) => handleMouseOver(event, value)}
                        onMouseLeave={handleMouseOut}
                        showWeekdayLabels
                        gutterSize={2}
                    />
                    {tooltip.visible && (
                        <Box
                            sx={{
                                position: "fixed",
                                top: tooltip.y + 10,
                                left: tooltip.x + 10,
                                background: "#333",
                                color: "#fff",
                                padding: "8px 12px",
                                borderRadius: "6px",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                pointerEvents: "none",
                                zIndex: 1000,
                            }}
                        >
                            {tooltip.content}
                        </Box>
                    )}
                </Box>
            </Container>

            {/* Модальное окно для добавления тренера */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "90%" : 400,
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        Добавление тренера
                    </Typography>
                    <TextField
                        fullWidth
                        label="Введите токен"
                        variant="outlined"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleTokenSubmit}
                    >
                        Отправить
                    </Button>
                </Box>
            </Modal>

            {/* Snackbar для уведомлений */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default StudentProfile;
