import axios from 'axios';
import * as api from "./API";

const apiClient = axios.create({
    baseURL: `${api.protocol}://${api.backendHost}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});
export default apiClient;