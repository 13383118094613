const et = {
    "buttons:flipper": "Flip pool pardal",
    "buttons:first": "Mine esimese sammu",
    "buttons:prev": "Mine eelmise käigu",
    "buttons:next": "Mine järgmise käigu",
    "buttons:play": "Play / lõpetada mängimine kõik käigud",
    "buttons:last": "Mine viimase käigu",
    "buttons:deleteVar": "Kustuta variatsioon",
    "buttons:promoteVar": "Edendada variatsioon",
    "buttons:deleteMoves": "Kustuta ülejäänud käigud siit",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Näita PGN praeguse mängu",
    "buttons:hidePGN": "Peida kuvatakse PGN",
    "buttons:getHint": "Anna vihje",
    "buttons:makeMove": "Näita järgmist käiku",
    "buttons:showSolution": "Näidake kogu lahendust",
    "chess:q": "L",
    "chess:k": "K",
    "chess:r": "V",
    "chess:b": "O",
    "chess:n": "R",
    "chess:Q": "L",
    "chess:K": "K",
    "chess:R": "V",
    "chess:B": "O",
    "chess:N": "R"
};
export default et;
