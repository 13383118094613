import axios from "axios";
import * as api from "./API";
import { IHomework } from "../Interfaces/Homework";
import apiClient from "./Fetcher";

export let GetPuzzleByOrderNumber = async (homeworkId: any, orderNumber: any) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homeworkrepository/GetPuzzleByOrderNumber/${homeworkId}/${orderNumber}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке головоломки, попробуйте еще раз",
            },
        };
        // Возвращаем собственный объект с информацией об ошибке
        return responseError;
    }
};

export let GetNextUnsolvedPuzzle = async (homeworkId: any, orderNumber: any, studentId: any) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homeworkrepository/GetNextUnsolvedPuzzle/${homeworkId}/${orderNumber}/${studentId}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке следующей неразгаданной головоломки, попробуйте еще раз",
            },
        };
        return responseError;
    }
};
export let GetHomeworkGrid = async (homeworkId: any) => {
    try {
        const response = await apiClient.get(`/homeworkrepository/grid/${homeworkId}`);
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке сетки домашних заданий, попробуйте еще раз",
            },
        };
        return responseError;
    }
};

export let GetStudentHomeworkGrid = async (homeworkId: any, studentId: any) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homeworkrepository/studentgrid/${homeworkId}/${studentId}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке сетки домашнего задания для студента, попробуйте еще раз",
            },
        };
        return responseError;
    }
};

export let GetStudentHomeworkRepo = async () => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/HomeworkRepository/GetStudentHomework`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке статистики домашних заданий студента, попробуйте еще раз",
            },
        };
        return responseError;
    }
};

export let GetStudentHomeworkRepoById = async (studentId: any) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homeworkrepository/GetStudentHomeworkById/${studentId}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке статистики домашних заданий студента по ID, попробуйте еще раз",
            },
        };
        return responseError;
    }
};

export let GetHomeworkStat = async (homeworkId: any, studentId: any) => {
    try {
        const response = await axios.get(
            `${api.protocol}://${api.backendHost}/api/homeworkrepository/Homeworkstat/${homeworkId}/${studentId}`,
            {
                withCredentials: true, // Разрешаем отправку куки с запросом
            }
        );
        return response.data;
    } catch (error) {
        const responseError = {
            status: 500,
            data: {
                message: "Ошибка при загрузке статистики домашнего задания, попробуйте еще раз",
            },
        };
        return responseError;
    }
};
