const it = {
    "buttons:flipper": "Capovolgere i lati del bordo",
    "buttons:first": "Vai alla prima mossa",
    "buttons:prev": "Vai alla precedente mossa",
    "buttons:next": "Vai alla prossima mossa",
    "buttons:play": "PLAY / STOP giocare tutte le mosse",
    "buttons:last": "Vai ultima mossa",
    "buttons:deleteVar": "Eliminare variazione",
    "buttons:promoteVar": "Promuovere variazione",
    "buttons:deleteMoves": "Eliminare muove resto da qui",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Visualizzazione PGN di partita in corso",
    "buttons:hidePGN": "Nascondere il PGN visualizzato",
    "buttons:getHint": "Dai un suggerimento",
    "buttons:makeMove": "Mostra la mossa successiva",
    "buttons:showSolution": "Mostra l'intera soluzione",
    "chess:q": "D",
    "chess:k": "R",
    "chess:r": "T",
    "chess:b": "A",
    "chess:n": "C",
    "chess:Q": "D",
    "chess:K": "R",
    "chess:R": "T",
    "chess:B": "A",
    "chess:N": "C"
};
export default it;
