const pt = {
    "buttons:flipper": "Virar os lados da placa",
    "buttons:first": "Ir para o primeiro movimento",
    "buttons:prev": "Ir para o movimento anterior",
    "buttons:next": "Ir para o próximo movimento",
    "buttons:play": "Reproduzir / parar de jogar todos os movimentos",
    "buttons:last": "Ir para o último movimento",
    "buttons:deleteVar": "Excluir variação",
    "buttons:promoteVar": "Promover a variação",
    "buttons:deleteMoves": "Excluir rest move daqui",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Exibir PGN do jogo atual",
    "buttons:hidePGN": "Ocultar a PGN exibida",
    "buttons:getHint": "Dê uma dica",
    "buttons:makeMove": "Mostre o próximo movimento",
    "buttons:showSolution": "Mostre toda a solução",
    "chess:q": "D",
    "chess:k": "R",
    "chess:r": "T",
    "chess:b": "B",
    "chess:n": "C",
    "chess:Q": "D",
    "chess:K": "R",
    "chess:R": "T",
    "chess:B": "B",
    "chess:N": "C"
};
export default pt;
