// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `cg-resize {
    display: block;
    position: absolute;
    right: -9px;
    bottom: -9px;
    width: 22px;
    height: 22px;
    cursor: nwse-resize;
    z-index: 10;
}

cg-resize::before, cg-resize::after {
    background: #000;
    content: '';
    position: absolute;
    width: 12px;
    height: 1px;
}

cg-resize::after {
    transform: translate(1px, 6px) rotate(
            -45deg);
}

cg-resize::before {
    width: 5px;
    transform: translate(7px, 8px) rotate(
            -45deg);
}

cg-resize::before {
    width: 5px;
    transform: translate(7px, 8px) rotate(
            -45deg);
}

cg-resize:hover, .resizing cg-resize {
    border-radius: 50%;
    background: rgba(214,79,0,0.5);
}`, "",{"version":3,"sources":["webpack://./src/css/resize.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI;mBACe;AACnB;;AAEA;IACI,UAAU;IACV;mBACe;AACnB;;AAEA;IACI,UAAU;IACV;mBACe;AACnB;;AAEA;IACI,kBAAkB;IAClB,8BAA8B;AAClC","sourcesContent":["cg-resize {\n    display: block;\n    position: absolute;\n    right: -9px;\n    bottom: -9px;\n    width: 22px;\n    height: 22px;\n    cursor: nwse-resize;\n    z-index: 10;\n}\n\ncg-resize::before, cg-resize::after {\n    background: #000;\n    content: '';\n    position: absolute;\n    width: 12px;\n    height: 1px;\n}\n\ncg-resize::after {\n    transform: translate(1px, 6px) rotate(\n            -45deg);\n}\n\ncg-resize::before {\n    width: 5px;\n    transform: translate(7px, 8px) rotate(\n            -45deg);\n}\n\ncg-resize::before {\n    width: 5px;\n    transform: translate(7px, 8px) rotate(\n            -45deg);\n}\n\ncg-resize:hover, .resizing cg-resize {\n    border-radius: 50%;\n    background: rgba(214,79,0,0.5);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
