const da = {
    "buttons:flipper": "Flip siderne af brættet",
    "buttons:first": "Gå til første skridt",
    "buttons:prev": "Gå til forrige træk",
    "buttons:next": "Gå til næste træk",
    "buttons:play": "Afspil / stoppe med at spille alle bevægelser",
    "buttons:last": "Gå til sidste træk",
    "buttons:deleteVar": "Slet variation",
    "buttons:promoteVar": "Fremme variation",
    "buttons:deleteMoves": "Slet resten bevæger sig fra her",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Visning PGN af aktuelle spil",
    "buttons:hidePGN": "Skjul den viste PGN",
    "buttons:getHint": "Giv et hint",
    "buttons:makeMove": "Vis næste træk",
    "buttons:showSolution": "Vis hele løsningen",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "T",
    "chess:b": "L",
    "chess:n": "S",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "T",
    "chess:B": "L",
    "chess:N": "S"
};
export default da;
