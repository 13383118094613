import axios from "axios";
import * as api from "./API";

export let GetPuzzle = async (themes, puzzleId) => {
  var response = await axios.get(
    `${api.protocol}://${api.backendHost}/api/puzzle/${themes}/${puzzleId}`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export let GetPuzzleWithRating = async (themes, puzzleId, min, max) => {
  var response = await axios.get(
    `${api.protocol}://${api.backendHost}/api/puzzle/${themes}/${puzzleId}/${min}/${max}`,
    {
      withCredentials: true,
    }
  );
  return response;
};

export let SolvedPuzzle = async (puzzle) => {
  try {
    var response = await axios.post(
      `${api.protocol}://${api.backendHost}/api/puzzle/solvedpuzzle`,
      puzzle,
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error){}
};
