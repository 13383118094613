import { i18n } from "./i18n/i18n-util";
import { loadAllLocales } from "./i18n/i18n-util.sync";
loadAllLocales();
function matchLoc(loc) {
    let m = loc.match(/(.{2})_(.{2})/);
    if (m) {
        return m[1];
    }
    return loc;
}
const i18next = function (loc) {
    const matchLocale = matchLoc(loc);
    return i18n()[matchLocale];
};
export { i18next };
