export var Layout;
(function (Layout) {
    Layout["Left"] = "left";
    Layout["Right"] = "right";
    Layout["Top"] = "top";
    Layout["Bottom"] = "bottom";
})(Layout || (Layout = {}));
export var PgnViewerMode;
(function (PgnViewerMode) {
    PgnViewerMode["Board"] = "board";
    PgnViewerMode["View"] = "view";
    PgnViewerMode["Edit"] = "edit";
    PgnViewerMode["Print"] = "print";
    PgnViewerMode["Puzzle"] = "puzzle";
})(PgnViewerMode || (PgnViewerMode = {}));
export var PieceStyle;
(function (PieceStyle) {
    PieceStyle["Wikipedia"] = "wikipedia";
    PieceStyle["Alpha"] = "alpha";
    PieceStyle["Uscf"] = "uscf";
    PieceStyle["Case"] = "case";
    PieceStyle["Condal"] = "condal";
    PieceStyle["Maya"] = "maya";
    PieceStyle["Merida"] = "merida";
    PieceStyle["Leipzig"] = "leipzig";
    PieceStyle["Beyer"] = "beyer";
})(PieceStyle || (PieceStyle = {}));
export var Theme;
(function (Theme) {
    Theme["Default"] = "default";
    Theme["Zeit"] = "zeit";
    Theme["Green"] = "green";
    Theme["Blue"] = "blue";
    Theme["Falken"] = "falken";
    Theme["Beyer"] = "beyer";
    Theme["Sportverlag"] = "sportverlag";
    Theme["Informator"] = "informator";
    Theme["Brown"] = "brown";
})(Theme || (Theme = {}));
