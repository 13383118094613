const fr = {
    "chess:q": "D",
    "chess:k": "R",
    "chess:r": "T",
    "chess:b": "F",
    "chess:n": "C",
    "chess:Q": "D",
    "chess:K": "R",
    "chess:R": "T",
    "chess:B": "F",
    "chess:N": "C",
    "buttons:flipper": "Retourner l'échiquier",
    "buttons:first": "Aller au premier coup",
    "buttons:prev": "Coup précédent",
    "buttons:next": "Coup suivant",
    "buttons:play": "Jouer / arrêter",
    "buttons:last": "Aller au dernier coup",
    "buttons:deleteVar": "Supprimer la variante",
    "buttons:promoteVar": "Promouvoir la variante",
    "buttons:deleteMoves": "Effacer ce coup et les suivants",
    "buttons:nags": "Menu NAGs",
    "buttons:pgn": "Afficher le PGN de la partie",
    "buttons:hidePGN": "Masquer le PGN affiché",
    "buttons:getHint": "Donnez un indice",
    "buttons:makeMove": "Afficher le prochain mouvement",
    "buttons:showSolution": "Afficher toute la solution",
    "nag:$0": "pas d'annotation",
    "nag:$1": "bon coup",
    "nag:$1_menu": "Bon coup",
    "nag:$2": "mauvais coup",
    "nag:$2_menu": "Mauvais coup",
    "nag:$3": "coup excellent",
    "nag:$3_menu": "Coup excellent",
    "nag:$4": "très mauvais coup",
    "nag:$4_menu": "Très mauvais coup",
    "nag:$5": "coup intéressant",
    "nag:$5_menu": "Coup intéressant",
    "nag:$6": "coup discutable",
    "nag:$6_menu": "Coup douteux",
    "nag:$7": "coup forcé",
    "nag:$7_menu": "Coup forcé",
    "nag:$8": "le seul coup",
    "nag:$8_menu": "Seul coup",
    "nag:$9": "le pire coup",
    "nag:$10": "position égale",
    "nag:$10_menu": "Position égale",
    "nag:$11": "chances égales, position calme",
    "nag:$12": "chances égales, position dynamique",
    "nag:$13": "position confuse",
    "nag:$13_menu": "Position confuse",
    "nag:$14": "léger avantage aux blancs",
    "nag:$14_menu": "Léger avantage aux blancs",
    "nag:$15": "léger avantage aux noirs",
    "nag:$15_menu": "Léger avantage aux noirs",
    "nag:$16": "les blancs sont mieux",
    "nag:$16_menu": "Avantage blanc",
    "nag:$17": "les noirs sont mieux",
    "nag:$17_menu": "Avantage noir",
    "nag:$18": "les blancs sont beaucoup mieux",
    "nag:$18_menu": "Les blancs sont gagnants",
    "nag:$19": "les noirs sont beaucoup mieux",
    "nag:$19_menu": "Les noirs sont gagnants",
    "nag:$20": "les Blancs ont un avantage écrasant",
    "nag:$21": "les Noirs ont un avantage écrasant",
    "nag:$22": "les blancs sont en zugzwang",
    "nag:$22_menu": "Les blancs sont en zugzwang",
    "nag:$23": "les noirs sont en zugzwang",
    "nag:$24": "les blancs ont un peu plus d'espace",
    "nag:$24_menu": "Plus d'espace",
    "nag:$25": "les noirs ont un peu plus d'espace",
    "nag:$26": "les blancs ont plus d'espace",
    "nag:$27": "les noirs ont plus d'espace",
    "nag:$28": "les blancs ont un advantage d'espace décisif",
    "nag:$29": "les noirs ont un advantage d'espace décisif",
    "nag:$30": "les blancs sont un peu mieux développés",
    "nag:$31": "les noirs sont un peu mieux développés",
    "nag:$32": "les blancs sont mieux développés",
    "nag:$32_menu": "Meilleur développement",
    "nag:$33": "les noirs sont mieux développés",
    "nag:$34": "les blancs ont un avantage de développement décisif",
    "nag:$35": "les noirs ont un avantage de développement décisif",
    "nag:$36": "les blans ont l'initiative",
    "nag:$36_menu": "Initiative",
    "nag:$37": "les noirs ont l'initiative",
    "nag:$38": "les blancs ont une initiative durable",
    "nag:$39": "les noirs ont une initiative durable",
    "nag:$40": "les blancs ont l'attaque",
    "nag:$40_menu": "Attaque",
    "nag:$41": "les noirs ont l'attaque",
    "nag:$42": "les blancs n'ont pas assez de compensation pour le matériel",
    "nag:$43": "les noirs n'ont pas assez de compensation pour le matériel",
    "nag:$44": "les blancs ont assez de compensation pour le matériel",
    "nag:$44_menu": "Avec compensation",
    "nag:$45": "les noirs ont assez de compensation pour le matériel",
    "nag:$46": "les blancs ont une nette compensation pour le matériel",
    "nag:$47": "les noirs ont une nette compensation pour le matériel",
    "nag:$48": "petit avantage blanc au centre",
    "nag:$49": "petit avantage noir au centre",
    "nag:$50": "avantage blanc au centre",
    "nag:$51": "avantage noir au centre",
    "nag:$52": "avantage blanc décisif au centre",
    "nag:$53": "avantage noir décisif au centre",
    "nag:$54": "petit avantage blanc sur l'aile roi",
    "nag:$55": "petit avantage noir sur l'aile roi",
    "nag:$56": "avantage blanc sur l'aile roi",
    "nag:$57": "avantage noir sur l'aile roi",
    "nag:$58": "avantage blanc décisif sur l'aile roi",
    "nag:$59": "avantage noir décisif sur l'aile roi",
    "nag:$60": "petit avantage blanc sur l'aile dame",
    "nag:$61": "petit avantage noir sur l'aile dame",
    "nag:$62": "avantage blanc sur l'aile dame",
    "nag:$63": "avantage noir sur l'aile dame",
    "nag:$64": "avantage blanc décisif sur l'aile dame",
    "nag:$65": "avantage noir décisif sur l'aile dame",
    "nag:$66": "première rangée blanche vulnérable",
    "nag:$67": "première rangée noire vulnérable",
    "nag:$68": "première rangée blanche solide",
    "nag:$69": "première rangée noire solide",
    "nag:$70": "roi blanc mal protégé",
    "nag:$71": "roi noir mal protégé",
    "nag:$72": "roi blanc bien protégé",
    "nag:$73": "roi noir bien protégé",
    "nag:$74": "roi blanc mal placé",
    "nag:$75": "roi noir mal placé",
    "nag:$76": "roi blanc bien placé",
    "nag:$77": "roi noir bien placé",
    "nag:$78": "structure de pions blanche très faible",
    "nag:$79": "structure de pions noire très faible",
    "nag:$80": "structure de pions blanche faible",
    "nag:$81": "structure de pions noire faible",
    "nag:$82": "structure de pions blanche plutôt solide",
    "nag:$83": "structure de pions noire plutôt solide",
    "nag:$84": "structure de pions blanche très solide",
    "nag:$85": "structure de pions noire très solide",
    // unsure here if I should use the plural form:
    //   cavalier(s) blancs(s) mal placé(s). It looks very "heavy".
    "nag:$86": "cavalier blanc mal placé",
    "nag:$87": "cavalier noir mal placé",
    "nag:$88": "cavalier blanc bien placé",
    "nag:$89": "cavalier noir bien placé",
    "nag:$90": "fou blanc mal placé",
    "nag:$91": "fou noir mal placé",
    "nag:$92": "fou blanc bien placé",
    "nag:$93": "fou noir bien placé",
    "nag:$94": "tour blanche mal placée",
    "nag:$95": "tour noire mal placée",
    "nag:$96": "tour blanche bien placée",
    "nag:$97": "tour noire bien placée",
    "nag:$98": "dame blanche mal placée",
    "nag:$99": "dame noire mal placée",
    "nag:$100": "dame blanche bien placée",
    "nag:$101": "dame noire bien placée",
    "nag:$102": "pièces blanches non coordonnées",
    "nag:$103": "pièces noires non coordonnées",
    "nag:$104": "pièces blanches bien coordonnées",
    "nag:$105": "pièces noires bien coordonnées",
    "nag:$106": "les blancs ont très mal joué l'ouverture",
    "nag:$107": "les noirs ont très mal joué l'ouverture",
    "nag:$108": "les blancs ont mal joué l'ouverture",
    "nag:$109": "les noirs ont mal joué l'ouverture",
    "nag:$110": "les blancs ont bien joué l'ouverture",
    "nag:$111": "les noirs ont bien joué l'ouverture",
    "nag:$112": "les blancs ont très bien joué l'ouverture",
    "nag:$113": "les noirs ont très bien joué l'ouverture",
    "nag:$114": "les blancs ont très mal joué le milieu de partie",
    "nag:$115": "les noirs ont très mal joué le milieu de partie",
    "nag:$116": "les blancs ont mal joué le milieu de partie",
    "nag:$117": "les noirs ont mal joué le milieu de partie",
    "nag:$118": "les blancs ont bien joué le milieu de partie",
    "nag:$119": "les noirs ont bien joué le milieu de partie",
    "nag:$120": "les blancs ont très bien joué le milieu de partie",
    "nag:$121": "les noirs ont très bien joué le milieu de partie",
    "nag:$122": "les blancs ont très mal joué la finale",
    "nag:$123": "les noirs ont très mal joué la finale",
    "nag:$124": "les blancs ont mal joué la finale",
    "nag:$125": "les noirs ont mal joué la finale",
    "nag:$126": "les blancs ont bien joué la finale",
    "nag:$127": "les noirs ont bien joué la finale",
    "nag:$128": "les blancs ont très bien joué la finale",
    "nag:$129": "les noirs ont très bien joué la finale",
    "nag:$130": "petit contrejeu pour les blancs",
    "nag:$131": "petit contrejeu pour les noirs",
    "nag:$132": "contrejeu pour les blancs",
    "nag:$132_menu": "Contrejeu",
    "nag:$133": "contrejeu pour les noirs",
    "nag:$134": "contrejeu décisif pour les blancs",
    "nag:$135": "contrejeu décisif pour les noirs",
    "nag:$136": "les blancs sont en zeitnot",
    "nag:$136_menu": "Zeitnot",
    "nag:$137": "les noirs sont en zeitnot",
    "nag:$138": "les blancs sont en zeitnot sévère",
    "nag:$139": "les noirs sont en zeitnot sévère",
    "nag:$140": "avec l'idée",
    "nag:$140_menu": "Avec l'idée",
    "nag:$146": "Nouveauté",
    "nag:$146_menu": "Nouveauté"
};
export default fr;
