// HomeworkTopics.jsx
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../Styles/ChessThemes.css"; // Используем общие стили
import { UserContext } from "./UserContext";

// Массив тем домашних заданий, сгруппированных по глобальным темам
const homeworkThemes = [
  {
    globalLabel: "Вилка",
    topics: [
      { id: 51, label: "Вилка (1000-1200)" },
      { id: 52, label: "Вилка (1200-1400)" },
      { id: 53, label: "Вилка (1400-1600)" },
      { id: 54, label: "Вилка (1600-1800)" },
      { id: 55, label: "Вилка (1800-2000)" },
    ],
  },
  {
    globalLabel: "Связка",
    topics: [
      { id: 56, label: "Связка (1000-1200)" },
      { id: 57, label: "Связка (1200-1400)" },
      { id: 58, label: "Связка (1400-1600)" },
      { id: 59, label: "Связка (1600-1800)" },
      { id: 60, label: "Связка (1800-2000)" },
    ],
  },
];

const HomeworkTopics = () => {
  const { user, setUser } = useContext(UserContext); // информация о юзере

  return (
    <div>
      <h2 align="center">Стандартные темы домашних заданий</h2>
      {homeworkThemes.map((themeGroup, index) => (
        <div key={index} className="theme-group">
          {/* Заголовок глобальной темы */}
          <h3 className="global-theme-title">{themeGroup.globalLabel}</h3>
          <div className="themes-row custom-spacing">
            {themeGroup.topics.map((homework) => (
              <div key={homework.id} className="theme-wrapper">
                <Link
                  to={
                    user.role == "student"
                      ? `/homeworkgrid/${homework.label}/${homework.id}/studentid/${user.id}`
                      : `/homeworkgrid/${homework.label}/${homework.id}`
                  }
                  className="theme-card"
                >
                  {homework.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeworkTopics;
