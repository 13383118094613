const hu = {
    "buttons:flipper": "Flip oldalán a tábla",
    "buttons:first": "Menjünk az első lépés",
    "buttons:prev": "Ugrás az előző mozgás",
    "buttons:next": "Ugrás a következő lépés",
    "buttons:play": "Lejátszás / stop playing minden mozog",
    "buttons:last": "Menj az utolsó lépés",
    "buttons:deleteVar": "Törlés variáció",
    "buttons:promoteVar": "Támogassák variáció",
    "buttons:deleteMoves": "Törlés többi mozog itt",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Kijelző PGN aktuális játék",
    "buttons:hidePGN": "Elrejtése a megjelenített PGN",
    "buttons:getHint": "Adj egy tippet",
    "buttons:makeMove": "Mutasd a következő lépést",
    "buttons:showSolution": "Mutasd meg a teljes megoldást",
    "chess:q": "V",
    "chess:k": "K",
    "chess:r": "B",
    "chess:b": "F",
    "chess:n": "H",
    "chess:Q": "V",
    "chess:K": "K",
    "chess:R": "B",
    "chess:B": "F",
    "chess:N": "H"
};
export default hu;
