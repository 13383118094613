const fi = {
    "buttons:flipper": "Käännä lauta",
    "buttons:first": "Mene ensimmäiseen siirtoon",
    "buttons:prev": "Mene edelliseen siirtoon",
    "buttons:next": "Mene seuraavaan siirtoon",
    "buttons:play": "Toista kaikki siirrot / Lopeta siirtojen toisto",
    "buttons:last": "Siirry viimeiseen liikkua",
    "buttons:deleteVar": "Poista muunnelma",
    "buttons:promoteVar": "Ylennä muunnelma",
    "buttons:deleteMoves": "Poista loput siirrot",
    "buttons:nags": "NAGs valikko",
    "buttons:pgn": "Näytä pelin PGN",
    "buttons:hidePGN": "Piilota PGN  näyttö",
    "buttons:getHint": "Anna vihje",
    "buttons:makeMove": "Näytä seuraava siirto",
    "buttons:showSolution": "Näytä koko ratkaisu",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "T",
    "chess:b": "L",
    "chess:n": "R",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "T",
    "chess:B": "L",
    "chess:N": "R"
};
export default fi;
