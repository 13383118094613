
export const handleAxiosError = (error) => {
    if (error.response) {
        throw new Error(
            `Ошибка ${error.response.status}: ${error.response.data?.message || "Ошибка на сервере"}`
        );
    } else if (error.request) {
        throw new Error("Нет ответа от сервера. Проверьте подключение.");
    } else {
        throw new Error(`Ошибка: ${error.message}`);
    }
};