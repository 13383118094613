import React, { useEffect, useLayoutEffect, useState } from 'react'
import * as uuid from 'uuid'
import { pgnView, pgnEdit, pgnPuzzle } from '@mliebelt/pgn-viewer'
import { GetPuzzleAnalys } from '../../Utils/API/Analysis'
import { useParams } from 'react-router-dom'

// Изменим сигнатуру компонента, чтобы удобнее принимать props
// ({ puzzleid, studentid }) вместо (puzzleid, studentid).
export function ChessPuzzle() {
    // const { puzzleid, studentid } = useParams(); // Извлекаем параметры из URL

    // ID для контейнера, куда будет рендерить pgnEdit
    // const id = 'board-' + uuid.v4();
    const id = 'board-' + uuid.v4();
    // const id = "board"


    // Локальные состояния, чтобы сохранить данные, пришедшие с сервера.
    // Предположим, что вернётся как минимум поле pgn.
    const [pgnData, setPgnData] = useState('');
    const [fenData, setFenData] = useState('');

    // Если нужно, можете добавить ещё какие-то поля из ответа.

    // Функция, которая делает запрос к серверу.
    // // Можно оформить её внутри useEffect или вынести отдельно.
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await GetPuzzleAnalys(puzzleid, studentid);
    //             console.log("ИНформация для анализа", response);

    //             // Предположим, что в ответе приходят поля .pgn и .solution.
    //             if (response) {
    //                 if (response.solution) {
    //                     setPgnData(response.solution);
    //                 }
    //                 if (response.fen) {
    //                     setFenData(response.fen);
    //                 }
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     // Вызываем асинхронную функцию
    //     fetchData();
    // }, [puzzleid, studentid]);

    /**
     * Вызываем pgnEdit, когда pgnData уже загружено из useEffect.
     * В зависимости от того, как вы хотите рендерить/обновлять доску,
     * можно использовать useLayoutEffect или useEffect.
     * Важно, чтобы этот эффект срабатывал после того, как pgnData установится.
    //  */
    useLayoutEffect(() => {

        var pgn = " e6 2. g4 Qh4#";
        pgnPuzzle(id, { position: 'rnbqkbnr/pppppppp/8/8/8/5P2/PPPPP1PP/RNBQKBNR b KQkq - 0 1', pgn: pgn });
    }, []);

    // Дополнительно можете вывести solutionData или другую инфу, если нужно.
    // Для демонстрации: <div>{solutionData}</div>

    // Возвращаем контейнер, в котором рендерится доска
    return (
        <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px'
        }}>
            <div id={id} />

            {/* Если вы хотите отдельно показывать, например, solutionData,
          или какую-то нотацию, сделайте дополнительный блок */}
            {/* <div style={{
                minWidth: '200px',
                border: '1px solid #ccc',
                padding: '10px'
            }}>
                <h4>Solution info</h4>
                <p>{solutionData}</p>
            </div> */}
        </div>
    )
}
