// themes.js
const themes = [
  "Тактика",
  "Мат в 2 хода миттеншпиль",
  "Эндшпиль",
  "Атака в эндшпиле",
  "Вилка в дебюте",
  "Связка эндшпиль",
  "Преимущество в миттеншпиле",
  "Атака в миттеншпиле",
  "Эндшпиль мат в 2 хода",
  "Мат в 1 ход миттеншпиль",
  "Эндшпиль мат в 1 ход",
  "Вилка эндшпиль",
  "Вилка миттеншпиль",
  "Тактика в дебюте",
  "Связка Миттеншпиль",
  "Тактика в ладейных окончаниях",
  "Взятие",
  "Вскрытое нападение"
];

const lichessTheme = {
  [themes[0]]: "middlegame",
  [themes[1]]: "mate mateIn2 middlegame short",
  [themes[2]]: "endgame",
  [themes[3]]: "endgame mate mateIn1 oneMove",
  [themes[4]]: "advantage fork opening short",
  [themes[5]]: "crushing endgame pin short",
  [themes[6]]: "advantage middlegame short",
  [themes[7]]: "crushing middlegame short",
  [themes[8]]: "endgame mate mateIn2 short",
  [themes[9]]: "mate mateIn1 middlegame oneMove",
  [themes[10]]: "endgame mate mateIn1 oneMove",
  [themes[11]]: "crushing endgame fork short",
  [themes[12]]: "advantage fork middlegame short",
  [themes[13]]: "advantage opening short",
  [themes[14]]: "crushing middlegame pin short",
  [themes[15]]: "crushing endgame rookEndgame short",
  [themes[16]]: "advantage hangingPiece middlegame short",
  [themes[17]]: "crushing discoveredAttack middlegame short",
  ["случайная"]: "random",
};

function findKeyByValue(obj, value) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

// Экспортируем themes и lichessTheme
export { themes, lichessTheme, findKeyByValue };
