const cs = {
    "buttons:flipper": "Flip po stranách desky",
    "buttons:first": "Jít na první tah",
    "buttons:prev": "Přejít na předchozí tah",
    "buttons:next": "Přejít na další tah",
    "buttons:play": "Přehrát / zastavit přehrávání všech tahů",
    "buttons:last": "Přejít na poslední tah",
    "buttons:deleteVar": "smazat variaci",
    "buttons:promoteVar": "podporovat změnu",
    "buttons:deleteMoves": "Odstranit odpočinku pohyby odtud",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Zobrazení PGN z aktuální hry",
    "buttons:hidePGN": "Skrýt zobrazené PGN",
    "buttons:getHint": "Dát nápovědu",
    "buttons:makeMove": "Ukažte další tah",
    "buttons:showSolution": "Ukažte celé řešení",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "V",
    "chess:b": "S",
    "chess:n": "J",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "V",
    "chess:B": "S",
    "chess:N": "J"
};
export default cs;
