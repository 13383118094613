const ro = {
    "buttons:flipper": "Flip părțile laterale ale plăcii",
    "buttons:first": "Du-te la prima mutare",
    "buttons:prev": "Du-te la mutarea anterioară",
    "buttons:next": "Du-te la următoarea mutare",
    "buttons:play": "Redare / opri redarea toate mișcările",
    "buttons:last": "Mergi la ultima mutare",
    "buttons:deleteVar": "Şterge variație",
    "buttons:promoteVar": "Să promoveze variația",
    "buttons:deleteMoves": "Șterge de aici se mută de odihnă",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Afișare PGN de joc curente",
    "buttons:hidePGN": "Ascundeți PGN afișat",
    "buttons:getHint": "Dați un indiciu",
    "buttons:makeMove": "Arată următoarea mișcare",
    "buttons:showSolution": "Arată întreaga soluție",
    "chess:q": "D",
    "chess:k": "R",
    "chess:r": "T",
    "chess:b": "N",
    "chess:n": "C",
    "chess:Q": "D",
    "chess:K": "R",
    "chess:R": "T",
    "chess:B": "N",
    "chess:N": "C"
};
export default ro;
