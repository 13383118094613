const nl = {
    "buttons:flipper": "Flip de zijkanten van het bord",
    "buttons:first": "Ga naar eerste zet",
    "buttons:prev": "Ga naar vorige zet",
    "buttons:next": "Ga naar de volgende stap",
    "buttons:play": "Play / stop spelen van alle moves",
    "buttons:last": "Ga naar laatste zet",
    "buttons:deleteVar": "Verwijder variatie",
    "buttons:promoteVar": "Bevorder variatie",
    "buttons:deleteMoves": "Verwijder rust verhuist van hier",
    "buttons:nags": "NAGs menu",
    "buttons:pgn": "Weergave PGN van het huidige spel",
    "buttons:hidePGN": "Verberg de weergegeven PGN",
    "buttons:getHint": "Geef een hint",
    "buttons:makeMove": "Laat de volgende zet zien",
    "buttons:showSolution": "Laat de hele oplossing zien",
    "chess:q": "D",
    "chess:k": "K",
    "chess:r": "T",
    "chess:b": "L",
    "chess:n": "P",
    "chess:Q": "D",
    "chess:K": "K",
    "chess:R": "T",
    "chess:B": "L",
    "chess:N": "P"
};
export default nl;
